import { useCallback, useEffect, useRef, useState } from 'react';
import { TimerManagerSingleton } from '../utils/timerManager';

const useDebounce = (value, delay = 0) => {
  const timerManager = TimerManagerSingleton.getInstance();
  const [dataDebouncer, setData] = useState(value);
  const handler = useCallback(() => setData(value), [value]);

  useEffect(() => {
    timerManager.registerAutoTimeout(handler, delay, 'debounceTimer');
  }, [value, delay, handler, timerManager]);

  return dataDebouncer;
};

export default useDebounce;
