const FULL_ACCESS = {
  SUPERADMIN: true,
  ADMIN: true,
  PROJECTLEADER: true,
  PLANNER: true,
  SUPERINTENDENT: true,
  LASTPLANNER: true,
  CLIENT: true,
  MANAGER: true,
  SUBTRADE: true
};

const FULL_LIMIT = {
  SUPERADMIN: false,
  ADMIN: false,
  PROJECTLEADER: false,
  PLANNER: false,
  SUPERINTENDENT: false,
  LASTPLANNER: false,
  CLIENT: false,
  MANAGER: false,
  SUBTRADE: false
};

const ONLY_EDITORS = {
  ...FULL_LIMIT,
  SUPERADMIN: true,
  ADMIN: true,
  PROJECTLEADER: true,
  PLANNER: true
};

const ALLOW = {
  ALL: FULL_ACCESS,
  ALL_EXCEPT_FOREMAN: {
    ...FULL_ACCESS,
    LASTPLANNER: false
  },
  ALL_EXCEPT_FOREMAN_AND_OWNER: {
    ...FULL_ACCESS,
    LASTPLANNER: false,
    CLIENT: false
  },
  ALL_EXCEPT_FOREMAN_AND_READONLY: {
    ...FULL_ACCESS,
    LASTPLANNER: false,
    MANAGER: false
  },
  EDITORS: ONLY_EDITORS,
  EDITORS_AND_SUPERINTENDENT: {
    ...ONLY_EDITORS,
    SUPERINTENDENT: true
  },
  EDITORS_AND_READONLY: {
    ...ONLY_EDITORS,
    MANAGER: true
  },
  EDITORS_AND_SUPERINTENDENT_AND_READONLY: {
    ...ONLY_EDITORS,
    SUPERINTENDENT: true,
    MANAGER: true
  },
  EDITORS_AND_SUPERINTENDENT_AND_PARTNER: {
    ...ONLY_EDITORS,
    SUPERINTENDENT: true,
    SUBTRADE: true
  },
  ONLY_ADMINS: {
    ...FULL_LIMIT,
    SUPERADMIN: true,
    ADMIN: true
  },
  ADMINS_AND_LEADER: {
    ...FULL_LIMIT,
    SUPERADMIN: true,
    ADMIN: true,
    PROJECTLEADER: true
  },
  ONLY_SUPERINTENDENT: {
    ...FULL_LIMIT,
    SUPERINTENDENT: true
  },
  ONLY_SUPERINTENDENT_AND_READONLY: {
    ...FULL_LIMIT,
    SUPERINTENDENT: true,
    MANAGER: true
  },
  ONLY_SUPERINTENDENT_AND_PARTNER: {
    ...FULL_LIMIT,
    SUPERINTENDENT: true,
    SUBTRADE: true
  },
  ONLY_SUPERINTENDENT_AND_READONLY_AND_PARTNER: {
    ...FULL_LIMIT,
    SUPERINTENDENT: true,
    MANAGER: true,
    SUBTRADE: true
  },
  NONE: FULL_LIMIT
};

export const GANTT_COLUMN_PERMISSIONS_BY_ROLE = {
  WBS_PRE_COL: ALLOW.ALL_EXCEPT_FOREMAN,
  WBS_INITIAL_COL: ALLOW.ALL_EXCEPT_FOREMAN,
  correlative_id: ALLOW.ALL_EXCEPT_FOREMAN,
  text: ALLOW.ALL_EXCEPT_FOREMAN,
  start_date: ALLOW.ALL_EXCEPT_FOREMAN,
  end_date: ALLOW.ALL_EXCEPT_FOREMAN,
  checked: ALLOW.EDITORS,
  buttons: ALLOW.EDITORS,
  unique_correlative_id: ALLOW.EDITORS_AND_SUPERINTENDENT,
  status: ALLOW.EDITORS_AND_SUPERINTENDENT,
  duration: ALLOW.EDITORS_AND_SUPERINTENDENT_AND_READONLY,
  progress: ALLOW.EDITORS_AND_SUPERINTENDENT_AND_READONLY,
  expected_progress: ALLOW.EDITORS_AND_SUPERINTENDENT,
  freeSlack: ALLOW.EDITORS_AND_SUPERINTENDENT,
  totalSlack: ALLOW.EDITORS_AND_SUPERINTENDENT,
  custom_predecessors: ALLOW.EDITORS_AND_SUPERINTENDENT,
  custom_sucessors: ALLOW.EDITORS_AND_SUPERINTENDENT,
  constraint_type: ALLOW.EDITORS_AND_SUPERINTENDENT,
  calendar_id: ALLOW.EDITORS_AND_SUPERINTENDENT,
  constraint_date: ALLOW.EDITORS_AND_SUPERINTENDENT,
  real_work: ALLOW.EDITORS_AND_SUPERINTENDENT,
  hhWorkTime: ALLOW.EDITORS_AND_SUPERINTENDENT,
  cost: ALLOW.EDITORS_AND_SUPERINTENDENT,
  used_cost: ALLOW.EDITORS_AND_SUPERINTENDENT,
  real_cost: ALLOW.EDITORS_AND_SUPERINTENDENT,
  ponderator: ALLOW.EDITORS_AND_SUPERINTENDENT,
  is_critical: ALLOW.EDITORS_AND_SUPERINTENDENT,
  start_base: ALLOW.EDITORS_AND_SUPERINTENDENT,
  end_base: ALLOW.EDITORS_AND_SUPERINTENDENT,
  expected_progress_base: ALLOW.EDITORS_AND_SUPERINTENDENT,
  work_base: ALLOW.EDITORS_AND_SUPERINTENDENT,
  cost_base: ALLOW.EDITORS_AND_SUPERINTENDENT,
  duration_base: ALLOW.EDITORS_AND_SUPERINTENDENT,
  responsables: ALLOW.EDITORS_AND_SUPERINTENDENT,
  subcontractId: ALLOW.EDITORS_AND_SUPERINTENDENT,
  tags: ALLOW.EDITORS_AND_SUPERINTENDENT,
  description: ALLOW.EDITORS_AND_SUPERINTENDENT_AND_PARTNER,
  early_start: ALLOW.EDITORS_AND_SUPERINTENDENT,
  early_finish: ALLOW.EDITORS_AND_SUPERINTENDENT,
  late_start: ALLOW.EDITORS_AND_SUPERINTENDENT,
  late_finish: ALLOW.EDITORS_AND_SUPERINTENDENT,
  calendarDuration: ALLOW.ALL_EXCEPT_FOREMAN_AND_OWNER
};

export const GANTT_COLUMN_DEFAULT_VISIBILITY = {
  correlative_id: ALLOW.ALL_EXCEPT_FOREMAN,
  text: ALLOW.ALL_EXCEPT_FOREMAN,
  start_date: ALLOW.ALL_EXCEPT_FOREMAN,
  end_date: ALLOW.ALL_EXCEPT_FOREMAN,
  checked: ALLOW.EDITORS,
  buttons: ALLOW.EDITORS,
  custom_sucessors: ALLOW.EDITORS,
  duration: ALLOW.EDITORS_AND_READONLY,
  progress: ALLOW.EDITORS_AND_READONLY,
  custom_predecessors: ALLOW.EDITORS_AND_SUPERINTENDENT,
  calendarDuration: ALLOW.ONLY_SUPERINTENDENT_AND_READONLY_AND_PARTNER,
  description: ALLOW.ONLY_SUPERINTENDENT_AND_PARTNER,
  start_base: ALLOW.ONLY_SUPERINTENDENT,
  end_base: ALLOW.ONLY_SUPERINTENDENT,
  WBS_PRE_COL: ALLOW.NONE,
  WBS_INITIAL_COL: ALLOW.NONE,
  unique_correlative_id: ALLOW.NONE,
  status: ALLOW.NONE,
  expected_progress: ALLOW.NONE,
  freeSlack: ALLOW.NONE,
  totalSlack: ALLOW.NONE,
  constraint_type: ALLOW.NONE,
  calendar_id: ALLOW.NONE,
  constraint_date: ALLOW.NONE,
  real_work: ALLOW.NONE,
  hhWorkTime: ALLOW.NONE,
  cost: ALLOW.NONE,
  used_cost: ALLOW.NONE,
  real_cost: ALLOW.NONE,
  ponderator: ALLOW.NONE,
  is_critical: ALLOW.NONE,
  expected_progress_base: ALLOW.NONE,
  work_base: ALLOW.NONE,
  cost_base: ALLOW.NONE,
  duration_base: ALLOW.NONE,
  responsables: ALLOW.NONE,
  subcontractId: ALLOW.NONE,
  tags: ALLOW.NONE,
  early_start: ALLOW.NONE,
  early_finish: ALLOW.NONE,
  late_start: ALLOW.NONE,
  late_finish: ALLOW.NONE
};

export const MODULE_ACTION_PERMISSIONS_BY_ROLE = {
  MASTERPLAN: {
    ACTIVITY_CARD: {
      SHOW_LINK_FLOAT_COLUMN: ALLOW.EDITORS_AND_SUPERINTENDENT
    },
    GANTT: {
      IMPORT_SCHEDULE: ALLOW.EDITORS,
      DOWNLOAD_SCHEDULE_TO_PDF: ALLOW.EDITORS_AND_SUPERINTENDENT_AND_READONLY,
      DOWNLOAD_SCHEDULE_TO_XML: ALLOW.EDITORS_AND_SUPERINTENDENT,
      DOWNLOAD_SCHEDULE_TO_EXCEL: ALLOW.EDITORS_AND_SUPERINTENDENT_AND_READONLY,
      DOWNLOAD_SCHEDULE_TO_P6_XML: ALLOW.EDITORS_AND_SUPERINTENDENT,
      COLUMNS: ALLOW.EDITORS_AND_SUPERINTENDENT,
      TOGGLE_SLACK_VISIBILITY: ALLOW.EDITORS_AND_SUPERINTENDENT,
      CHANGE_COLOR_FOR_STATUS: ALLOW.ALL_EXCEPT_FOREMAN,
      CHANGE_COLOR_FOR_SUBCONTRACT: ALLOW.ALL_EXCEPT_FOREMAN,
      CHANGE_COLOR_FOR_TAGS: ALLOW.ALL_EXCEPT_FOREMAN,
      CHANGE_COLOR_FOR_CRITICALPATH: ALLOW.EDITORS_AND_SUPERINTENDENT,
      CHANGE_COLOR_ROW_FOR_WBS: ALLOW.ALL_EXCEPT_FOREMAN,
      GANTT_ADD_USERS_ITEMS: ALLOW.ADMINS_AND_LEADER
    },
    CALENDARS: {
      CLONE: ALLOW.EDITORS,
      IMPORT_HOLIDAYS: ALLOW.EDITORS,
      DELETE: ALLOW.EDITORS
    }
  },
  LOOKAHEAD: {
    GANTT: {
      SHOW_CHECKBOXES: ALLOW.ALL_EXCEPT_FOREMAN_AND_READONLY,
      BULK_EDIT_BAR: ALLOW.ALL_EXCEPT_FOREMAN_AND_READONLY
    }
  },
  GENERAL: {
    SETTINGS: {
      ORGANIZATION_SETTINGS: ALLOW.ONLY_ADMINS,
      PROJECT_SETTINGS: ALLOW.ADMINS_AND_LEADER
    }
  }
};
