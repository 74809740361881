import { useCallback } from 'react';
import { userService } from '../services/user.service';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { userActions } from '../redux/actions/userActions';
import { addBreadcrumbUtil } from '../monitor/monitor';

const USER_QUERY_KEY = 'user';

const handleMutationError = (context, metadata = {}) => {
  addBreadcrumbUtil('App', context, 'warning', metadata);
};

export const useUserMutation = (initialUser) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleImageUpdate = useCallback(
    (imageUrl) => {
      localStorage.setItem(
        'user',
        JSON.stringify({ ...initialUser, image: imageUrl })
      );
      dispatch(userActions.setUserUpdateImage(imageUrl));
    },
    [initialUser, dispatch]
  );

  const imageMutation = useMutation(
    (imageUrl) => userService.uploadUserImage(imageUrl),
    {
      onSuccess: (imageUrl) => {
        handleImageUpdate(imageUrl);
        queryClient.invalidateQueries(USER_QUERY_KEY);
      },
      onError: (error) => {
        handleMutationError('Error updating user image', {
          error,
          userId: initialUser?.id
        });
      }
    }
  );

  const handleImageChange = useCallback(
    (file) => {
      const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

      if (!ALLOWED_TYPES.includes(file.type)) {
        throw new Error('Invalid file type.');
      }

      return imageMutation.mutateAsync(file).catch((error) => {
        throw error;
      });
    },
    [imageMutation]
  );

  return {
    handleImageChange,
    isUpdating: imageMutation.isLoading,
    error: imageMutation.error
  };
};
