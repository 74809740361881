import { useDispatch } from 'react-redux';
import * as projectActions from '../redux/slices/projectSlice';
import { compareValues } from '../utils';
import { getCurrentCompany } from '../utils/userUtils';
import { trackingEvent } from '../analytics';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import { getProjectCurrentSector, getModuleView } from '../utils/projectUtils';
import useMasterplanPermissions from './useMasterplanPermissions';
import { useHistory } from 'react-router-dom';

import EventEmitter from 'react-native-eventemitter';
import { TimerManagerSingleton } from '../utils/timerManager';
const waitTime = 150;

export const PROJECT_SELECTION_EVENT = 'project_selection';
export const SCHEDULE_SELECTION_EVENT = 'schedule_selection';

export const HOME_CARD_VIEW = 'home_card_view';
export const HOME_TIMELINE_VIEW = 'home_timeline_view';
export const SETTINGS_USER_VIEW = 'settings_user_view';

export const useSelectProject = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userGanttPermissions = useMasterplanPermissions();
  const timerManager = TimerManagerSingleton.getInstance();

  const onClickProject = (project, source, analytics = {}) => {
    const { id, sectors, name } = project;
    if (!sectors) {
      return;
    }
    const modulePath = getModuleView(userGanttPermissions.gantt);
    const company = getCurrentCompany();
    const sector = getProjectCurrentSector(sectors);
    const activeSectors = sectors
      .filter(({ projectId }) => projectId === id)
      .filter(({ stage }) => stage !== 'deleted')
      .sort(compareValues('order'));

    const [eventName, eventData] =
      source === HOME_TIMELINE_VIEW
        ? [
            SCHEDULE_SELECTION_EVENT,
            { schedule_name: sector?.name, schedule_id: sector?.id }
          ]
        : [PROJECT_SELECTION_EVENT, { project_id: id, project_name: name }];

    const event = {
      event_source: source,
      company_name: company.name,
      company_id: company.id,
      ...analytics,
      ...eventData
    };

    trackingEvent(eventName, event, AMPLITUDE_SERVICE);

    dispatch(projectActions.setProject(id));
    dispatch(projectActions.setAllSectors(activeSectors));
    dispatch(projectActions.setSector(sector?.id));
    sessionStorage.setItem('currentProject', JSON.stringify(project));
    EventEmitter.emit('loadScheduleView');

    timerManager.registerAutoTimeout(
      () => history.push(modulePath),
      waitTime,
      'loadScheduleView'
    );
  };

  return { onClickProject };
};
