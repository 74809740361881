export const PERMISSIONS = {
  MASTERPLAN: {
    GANTT: 'masterplan.gantt',
    RESOURCES: 'masterplan.resources'
  },
  ANALYTICS: {
    CNC: 'analytics.cnc',
    PCR: 'analytics.pcr',
    PPC: 'analytics.ppc',
    SCURVE: 'analytics.scurve',
    SECTORADVANCEMENT: 'analytics.sectoradvancement',
    OVERDUE: 'analytics.overdue'
  },
  CONFIGURATION: {
    COMPANY: 'configuration.company',
    PROJECT: 'configuration.project',
    USER: 'configuration.user'
  },
  LOOKAHEAD: {
    CONSTRAINTS: 'lookahead.constraints',
    RESOURCES: 'lookahead.resources',
    PLAN: 'lookahead.plan'
  },
  WEEKLYPLAN: {
    CNCS: 'weeklyplan.cncs',
    PLAN: 'weeklyplan.plan'
  }
};

const DEFAULT_PERMISSIONS = {
  SUPERADMIN: true,
  ADMIN: true,
  PROJECTLEADER: true,
  PLANNER: true,
  SUPERINTENDENT: true,
  LASTPLANNER: true,
  CLIENT: true,
  MANAGER: true,
  SUBTRADE: true
};

export const PARTIAL_PERMISSION_PER_COLUMN = {
  freeSlack: {
    ...DEFAULT_PERMISSIONS,
    LASTPLANNER: false,
    CLIENT: false,
    MANAGER: false,
    SUBTRADE: false
  },
  totalSlack: {
    ...DEFAULT_PERMISSIONS,
    LASTPLANNER: false,
    CLIENT: false,
    MANAGER: false,
    SUBTRADE: false
  }
};

export const PARTIAL_PERMISSION_PER_MODULE = {
  MASTERPLAN: {
    ACTIVITY_CARD: {
      SHOW_LINK_FLOAT_COLUMN: {
        ...DEFAULT_PERMISSIONS,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      }
    },
    GANTT: {
      IMPORT_SCHEDULE: {
        ...DEFAULT_PERMISSIONS,
        SUPERINTENDENT: false,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      DOWNLOAD_SCHEDULE_TO_PDF: {
        ...DEFAULT_PERMISSIONS,
        LASTPLANNER: false,
        CLIENT: false,
        SUBTRADE: false
      },
      DOWNLOAD_SCHEDULE_TO_XML: {
        ...DEFAULT_PERMISSIONS,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      DOWNLOAD_SCHEDULE_TO_P6_XML: {
        ...DEFAULT_PERMISSIONS,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      DOWNLOAD_SCHEDULE_TO_EXCEL: {
        ...DEFAULT_PERMISSIONS,
        LASTPLANNER: false,
        CLIENT: false,
        SUBTRADE: false
      },
      COLUMNS: {
        ...DEFAULT_PERMISSIONS,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      TOGGLE_SLACK_VISIBILITY: {
        ...DEFAULT_PERMISSIONS,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      CHANGE_COLOR_FOR_STATUS: {
        ...DEFAULT_PERMISSIONS
      },
      CHANGE_COLOR_FOR_SUBCONTRACT: {
        ...DEFAULT_PERMISSIONS
      },
      CHANGE_COLOR_FOR_TAGS: {
        ...DEFAULT_PERMISSIONS
      },
      CHANGE_COLOR_FOR_CRITICALPATH: {
        ...DEFAULT_PERMISSIONS,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      CHANGE_COLOR_ROW_FOR_WBS: {
        ...DEFAULT_PERMISSIONS
      }
    },
    CALENDARS: {
      CLONE: {
        ...DEFAULT_PERMISSIONS,
        SUPERINTENDENT: false,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      IMPORT_HOLIDAYS: {
        ...DEFAULT_PERMISSIONS,
        SUPERINTENDENT: false,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      DELETE: {
        ...DEFAULT_PERMISSIONS,
        SUPERINTENDENT: false,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      }
    }
  },
  LOOKAHEAD: {
    GANTT: {
      SHOW_CHECKBOXES: {
        ...DEFAULT_PERMISSIONS,
        LASTPLANNER: false,
        MANAGER: false
      },
      BULK_EDIT_BAR: {
        ...DEFAULT_PERMISSIONS,
        LASTPLANNER: false,
        MANAGER: false
      }
    }
  },
  GENERAL: {
    SETTINGS: {
      ORGANIZATION_SETTINGS: {
        ...DEFAULT_PERMISSIONS,
        PROJECTLEADER: false,
        PLANNER: false,
        SUPERINTENDENT: false,
        SUBTRADE: false,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false
      },
      PROJECT_SETTINGS: {
        ...DEFAULT_PERMISSIONS,
        PLANNER: false,
        SUPERINTENDENT: false,
        SUBTRADE: false,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false
      }
    }
  }
};
